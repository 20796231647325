import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';


// New table system
import MUIDataTable from "mui-datatables";

const useStyles = makeStyles((theme) => ({
  pageTitle: {
	  borderBottom: `1px solid ${theme.palette.background.default}`,
  },
  header: {
	  backgroundColor: theme.palette.background.dark,
	  color: theme.palette.secondary.light,
	  fontWeight: 600,
	  fontSize: 16,
  },
  runner: {
	backgroundColor: theme.palette.secondary.light,
	color: theme.palette.secondary.contrastText,
	fontSize: 14,
	fontWeight: 300,
  },
  timeCell: {
	fontSize: 14,
	fontWeight: 600,  
  },
  DNF: {
	fontSize: 14,
	fontWeight: 600,  
	color: theme.palette.primary.dark,
  },
  mediaGroup: {
	  width: 600,
  },
  mediaWinner: {
	  height: 400,
  },
  metrics: {
	width: 'fit-content',
    color: theme.palette.secondary.contrastText,
    '& svg': {
      margin: theme.spacing(0.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },  
  },
  table: {
	color: theme.palette.secondary.primary,
  },
  raceReportLink: {
	color: theme.palette.primary.main,
  },
	firstColumn: {
	position: 'sticky',
	left: 0,
	zIndex: 1,
	backgroundColor: 'blue',
},
}));

// Table data definition...
const columns = [
 {
  name: "Runner",
  options: {
   filter: true,
   sort: false,
   select: false,
	  customBodyRender: (value, tableMeta) => {
		  if (tableMeta.colIndex === 0) {
			  return <div className="firstColumn">{value}</div>;
		  }
		  return value;
	  },
  }
 },
"Yard 1", "Yard 2", "Yard 3", "Yard 4", "Yard 5", "Yard 6", "Yard 7", "Yard 8", "Yard 9", "Yard 10", "Yard 11",
];

const data = [
 ["Ryan Horne [LOS]", '51:43', '51:37', '53:28', '52:49', '52:53', '55:09', '53:00', '53:22', '53:05', '53:01', '48:48'],
 ['Matthew Pfahl', '51:24', '51:35', '53:19', '52:47', '52:52', '54:48', '53:12', '53:22', '53:05', '54:56', 'DNF'],
 ['Anton Laptsenak', '51:01', '48:21', '46:56', '52:15', '52:41', '55:03', '55:09', '57:09', '56:52', 'DNF'],
 ['Joshua Pfahl', '51:00', '50:05', '52:08', '48:52', '53:08', '55:13', '55:00', 'DNF'],
 ['Andrew Todd', '51:18', '48:14', '52:44', '52:10', '52:36', '55:08', 'DNF'],
 ['TJ', '51:00', '50:05', '52:07', '50:01', '50:50', '54:52', 'DNF'],
 ['Bradley Pfahl', '51:00', '51:36', '53:38', '55:41', 'DNF'],
 ['Bryce Houdeshel', '51:00', '50:05', '52:07', '51:48', 'DNF'],
 ['Greg Korhonen', '51:00', '50:05', '52:07', '54:48', 'DNF'],
 ['Chelsea Pfahl', '51:00', '58:32', 'DNF'],
 ['Jenn Horne', '58:34', 'DNF'],
 ['Krysten Zigga', 'DNF', ],
 ['Logan Pfahl', 'DNF', ],
 ['Mady Pfahl', 'DNF',],
 ['Richard Pfahl', 'DNF',],
 ['Katya Sadeeva', 'DNF',]
];

// 2022 results
const columns2022 = [
 {
  name: "Runner",
  options: {
   filter: true,
   sort: false,
   select: false,
	  customBodyRender: (value, tableMeta) => {
		  if (tableMeta.colIndex === 0) {
			  return <div className="firstColumn">{value}</div>;
		  }
		  return value;
	  },
  }
 },
"Yard 1", "Yard 2", "Yard 3", "Yard 4", "Yard 5", "Yard 6", "Yard 7", "Yard 8", "Yard 9", "Yard 10", "Yard 11", "Yard 12", "Yard 13", "Yard 14",
];

const columns2023 = [
	{
		name: "Runner",
		options: {
			filter: true,
			sort: false,
			select: false,
			customBodyRender: (value, tableMeta) => {
				if (tableMeta.colIndex === 0) {
					return <div className="firstColumn">{value}</div>;
				}
				return value;
			},
		}
	},
	"Yard 1", "Yard 2", "Yard 3", "Yard 4", "Yard 5", "Yard 6", "Yard 7", "Yard 8", "Yard 9", "Yard 10", "Yard 11", "Yard 12", "Yard 13", "Yard 14", "Yard 15", "Yard 16", "Yard 17", "Yard 18", "Yard 19",
];

const columns2024 = [
	{
		name: "Runner",
		options: {
			filter: true,
			sort: false,
			select: false,
			customBodyRender: (value, tableMeta) => {
				if (tableMeta.colIndex === 0) {
					return <div className="firstColumn">{value}</div>;
				}
				return value;
			},
		}
	},
	"Yard 1", "Yard 2", "Yard 3", "Yard 4", "Yard 5", "Yard 6", "Yard 7", "Yard 8", "Yard 9", "Yard 10", "Yard 11", "Yard 12", "Yard 13", "Yard 14", "Yard 15", "Yard 16", "Yard 17", "Yard 18",
];

const columns2021 = [
	{
		name: "Runner",
		options: {
			filter: true,
			sort: false,
			select: false,
		}
	},
	"Yard 1", "Yard 2", "Yard 3", "Yard 4", "Yard 5", "Yard 6", "Yard 7", "Yard 8", "Yard 9", "Yard 10", "Yard 11",
];


const data2022 = [
	["Aaron Bowers [LOS]", '55:07', '55:34', '51:34', '52:22', '51:45', '53:56', '52:58', '52:34', '51:39', '53:11', '54:18', '55:00*', '51:44*', '40:09', 'DNF'],
	['Matthew Pfahl', '54:09', '56:17', '54:00', '56:12', '56:39', '56:34', '54:14', '54:24', '55:24', '55:12', '56:16', '56:46*', '51:47*', 'DNF'],
	["Ryan Horne", '55:05', '55:45', '53:59', '56:05', '56:16', '56:32', '54:41', '54:58', '55:28', '54:46', '55:12', '56:14*', 'DNF'],
	['Anton Laptsenak', '54:12', '55:46', '53:16', '56:04', '56:40', '56:25', '54:19', '55:10', '54:50', '57:07', '58:14*', 'DNF'],
	['Dan Pinto', '54:21', '55:25', '51:55', '56:15', '55:54', '56:22', '54:22', '54:48', '55:13', '55:49', 'DNF'],
	['William Pinto', '54:18', '55:24', '51:43', '53:43', '53:47', '56:36', '54:56', '57:12', '55:39', 'DNF'],
	['John Burnside', '54:22', '55:29', '52:00', '53:30', '53:43', '53:32', '52:46', '52:42', 'DNF'],
	['Joshua Pfahl', '54:54', '55:41', '53:33', '56:02', '55:56', '56:26', '54:45', '56:05', 'DNF'],
	['Samkit Shah', '54:37', '55:39', '53:20', '54:31', '56:32', '57:18', '53:48', 'DNF'],
	['Greg Korhonen', '54:58', '55:18', '51:54', '53:45', '55:49', '56:20', '54:47', 'DNF'],
	['Elizabeth Cohen', '54:51', '56:42', '53:10', '55:19', '55:46', '57:46', '58:04', 'DNF'],
	['Brian Packard', '54:11', '55:16', '51:59', '53:29', '51:14', '54:21', '52:52', 'DNF'],
	['Bryce Houdeshel', '54:59', '55:48', '53:32', '56:10', '55:58', '59:05', 'DNF'],
	['Eric Kishbaugh', '54:59', '55:53', '52:01', '56:16', '56:30', '59:06', 'DNF'],
	['Jason Sumner', '54:16', '55:42', '51:45', '50:53', '56:21', 'DNF'],
	['Richard Cohen', '54:41', '55:31', '52:02', '54:20', 'DNF'],
	['Ben Miller', '54:08', '55:35', '51:36', '54:29', 'DNF'],
	['William Pinto Sr.', '54:19', '55:26', '51:57', 'DNF'],
	['Tyler Hemple', '55:00', '55:38', '53:26', 'DNF'],
	['Tony Pinto', '55:01', '55:33', '53:42', 'DNF'],
	['Camron Craig', '54:10', '55:28', '54:35', 'DNF'],
	['Brad Pfahl', '55:13', '55:52', '55:11', 'DNF'],
	['Greg Amend', '55:10', '55:37', 'DNF'],
	['Chelsea Pfahl', '54:37', '56:26', 'DNF'],
	['Nina Petropoulos', '54:23', 'DNF',],
	['Jenn Horne', '55:14', 'DNF'],
	['Richard Pfahl', '58:31', 'DNF',],
	['Chelsey Korhonen', '58:56', 'DNF',],
	['Krysten Zigga', 'DNF', ],
	['Logan Pfahl', 'DNF', ],
	['Mady Pfahl', 'DNF',],
	['Alyssa Kehm', 'DNF',],
	['Valerie Miller', 'DNF',],
	['Michele Zigga', 'DNF',],
	['Rob Miller', 'DNF',],
	['Michael Trygar', 'DNF',],
	['Eric Zigga', 'DNF',],
];

const data2023 = [
	["Ryan Horne [LOS]", '55:14', '52:07', '52:52', '53:27', '53:27', '52:31', '52:24', '53:13', '53:23', '53:56', '52:36', '53:45', '48:30', '49:33', '49:56', '52:08', '51:08', '50:28', '49:37'],
	["Alec Fazio", '54:32', '51:33', '43:53', '50:14', '50:58', '51:58', '52:45', '52:30', '53:48', '54:36', '53:23', '53:20', '51:12', '53:37', '53:40', '53:57', '49:34', '55:01', 'DNF'],
	["Dan Pinto", '55:08', '51:52', '56:03', '56:16', '54:42', '55:18', '54:26', '54:37', '54:55', '54:38', '53:37', '51:00', '47:33', '47:16', '47:53', '51:45', 'DNF'],
	["Mary Namestnik", '54:34', '51:18', '52:33', '50:48', '49:36', '52:00', '47:19', '47:05', '49:30', '50:07','50:57', '56:15', '47:16', '51:35', 'DNF'],
	["Bryce Houdeshel", '56:29', '52:08', '43:23', '54:07', '53:38', '53:48', '53:01', '53:08', '53:38', '54:44', '54:28', '54:52', '48:36', 'DNF'],
	["Matthew Livingstone", '51:32', '51:32', '44:04', '51:11', '50:37', '52:19', '50:01', '50:57', '52:19', '53:58', '53:53', '53:54', 'DNF'],
	["Josh Burnside", '54:47', '51:41', '43:24', '52:36', '52:46', '52:30', '52:20', '52:55', '53:20', '53:54', '52:04', 'DNF'],
	["Patrick Burkhardt", '55:10', '51:50', '56:06', '56:18', '54:44', '55:42', '54:29', '54:35', '54:29', '54:40', '55:06', 'DNF'],
	["William Pinto", '55:08', '52:45', '56:04', '56:28', '54:40', '55:20', '54:19', '54:38', '54:56', '56:27', '57:31', 'DNF'],
	["Dave Marsico Jr.", '54:45', '45:45', '43:07', '42:56', '44:56', '49:10', '46:45', '47:04', '50:14', '55:38', 'DNF'],
	["Matthew Pfahl", '55:03', '53:07', '57:11', '53:47', '55:00', '55:10', '54:18', '54:19', '54:07', '55:41', 'DNF'],
	["Matthew Gill", '54:43', '51:21', '52:31', '50:57', '50:36', '52:09', '49:59', '48:50', '45:17', 'DNF'],
	["Sara Korba", '54:35', '51:26', '52:30', '50:56', '50:32', '52:15', '50:13', '51:11', '52:32', 'DNF'],
	["Aaron Taube", '53:54', '42:35', '42:48', '41:21', '43:33', '44:52', '45:09', '45:31', 'DNF'],
	["Joshua Pfahl", '55:11', '52:32', '55:43', '54:09', '55:14', '55:37', '54:48', '57:13', 'DNF'],
	["Greg Korhonen", '53:48', '51:42', '43:20', '53:01', '52:49', '54:46', '54:35', '57:15', 'DNF'],
	["Laure Spake", '54:45', '49:02', '52:35', '51:04', '51:42', '52:17', 'DNF'],
	["Anthony Yacina", '55:18', '51:48', '52:44', '51:28', '51:45', '53:16', 'DNF'],
	["Will Ferrill", '55:15', '52:15', '54:01', '54:33', '54:28', '58:00', 'DNF'],
	["Matthew Paul", '57:51', '51:31', '44:00', '53:20', '54:07', '58:18', 'DNF'],
	["Robert Bridge", '54:48', '51:35', '44:02', '53:40', '54:14', '58:20', 'DNF'],
	["Brian Packard", '55:04', '52:02', '55:47', '52:33', '54:16', '58:41', 'DNF'],
	["Nina Petropoulos", '55:02', '52:18', '56:13', '52:33', '55:02', '58:42', 'DNF'],
	["Andrew Todd", '54:30', '53:12', '59:57', '54:07', '57:48', 'DNF'],
	["Camron Craig", '54:57', '57:21', '57:58', '56:23', 'DNF'],
	["Tony Pinto", '55:27', '53:21', '57:00', '59:35', 'DNF'],
	["Jason Sumner", '56:41', '51:07', '58:52', 'DNF'],
	["Richard Cohen", '54:55', '51:45', '52:41', 'DNF'],
	["William Pinto Sr.", '54:58', '52:22', '57:04', 'DNF'],
	["Elizabeth Cohen", '55:26', '53:10', '59:33', 'DNF'],
	["Brad Pfahl", '55:37', '54:33', 'DNF'],
	["Bethany Jutzy", '55:40', '58:31', 'DNF'],
	["Jenn Horne", '55:42', 'DNF'],
	["Fayth Stephenson", '55:18', 'DNF'],
];

const data2024 = [
	["Tommy Diehl [LOS]", '52:42', '51:29', '53:52', '55:46', '54:01', '53:21', '54:29', '54:59', '53:41', '54:33', '53:51', '54:17', '50:50', '53:33', '54:40', '55:45', '50:41', '51:35'],
	["Mary Namestnik", '53:49', '52:18', '53:41', '54:20', '53:35', '55:24', '54:48', '55:37', '53:54', '55:11', '55:09', '54:40', '50:41', '51:25', '49:36', '52:07', '58:05', 'DNF'],
	["Matthew Pfahl", '52:17', '54:03', '54:02', '56:22', '54:02', '55:09', '54:39', '54:44', '54:11', '55:45', '55:58', '54:07', '51:03', '56:29', 'DNF'],
	["Laure Spake", '52:44', '53:36', '52:44', '54:11', '54:05', '55:11', '55:00', '54:48', '55:20', '56:38', 'DNF'],
	["Aaron Taube", '52:04', '50:33', '52:40', '54:04', '53:56', '54:52', '55:23', '56:22', '55:10', '56:09', 'DNF'],
	["Josh Burnside", '53:05', '50:18', '52:19', '54:19', '53:54', '54:24', '54:32', '54:37', '53:38', 'DNF'],
	["Dan Pinto", '53:09', '54:10', '54:50', '56:24', '53:53', '56:12', '55:26', '57:32', 'DNF'],
	["William Pinto", '53:38', '53:39', '51:55', '56:07', '53:15', '55:59', '58:09', '59:13', 'DNF'],
	["Will Ferrill", '53:20', '51:45', '54:57', '55:55', '53:59', '54:43', '55:42', 'DNF'],
	["Matthew Ford", '54:08', '54:38', '55:26', '56:05', '55:51', '56:16', '57:13', 'DNF'],
	["Cory Walter", '52:01', '50:29', '51:38', '52:49', '51:51', '55:05', '56:18', 'DNF'],
	["Tracy Dimond", '53:50', '53:26', '53:54', '55:55', '56:28', '59:54', 'DNF'],
	["Chris Cunningham", '52:13', '51:00', '52:38', '57:43', '57:23', '59:53', 'DNF'],
	["Olivier Pelletier", '52:22', '50:39', '51:59', '54:39', '56:48', 'DNF'],
	["Josh Pfahl", '52:52', '53:13', '52:27', '56:50', '57:20', 'DNF'],
	["Ryan Horne", '53:52', '50:20', '54:59', '56:01', '54:09', 'DNF'],
	["Bryce Houdeshel", '53:54', '54:08', '55:05', '58:29', '57:22', 'DNF'],
	["Greg Korhonen", '53:11', '53:45', '54:30', '55:48', '57:21', 'DNF'],
	["Bryan Kulbacki", '53:16', '53:24', '52:46', '55:13', '55:10', 'DNF'],
	["Caitlin McFadden", '52:11', '50:45', '52:38', '58:15', '57:27', 'DNF'],
	["Meghan Tomczyk", '57:35', '53:34', '55:19', '58:21', 'DNF'],
	["Terry Rosynsky", '52:46', '53:22', '53:00', '56:13', 'DNF'],
	["Jennifer Toti", '54:01', '55:21', '58:31', '57:04', 'DNF'],
	["Josh Visalli", '53:24', '53:49', '55:28', '57:55', 'DNF'],
	["Terry Rosynsky", '52:46', '53:22', '53:00', '56:13', 'DNF'],
	["Andrew Todd", '52:48', '50:22', '54:34', 'DNF'],
	["Sean Sullivan", '53:40', '56:28', '58:35', 'DNF'],
	["William Pinto Sr.", '53:08', '56:38', '58:22', 'DNF'],
	["Tony Pinto", '53:55', '56:39', '58:40', 'DNF'],
	["Dan King", '54:19', '55:49', '58:28', 'DNF'],
	["Patrick Burkhardt", '54:19', '55:49', '58:28', 'DNF'],
	["Eric Kishbaugh", '53:46', '53:53', '55:27', 'DNF'],
	["Patrick Burkhardt", '53:32', '53:55', '55:32', 'DNF'],
	["Richard Cohen", '52:40', '51:43', '53:59', 'DNF'],
	["Brian Foster", '52:34', '50:50', '52:18', 'DNF'],
	["Logan Pfahl", '52:03', '54:22', 'DNF'],
	["Jason Sumner", '52:36', '54:41', 'DNF'],
	["Fayth Stephenson", '57:05', 'DNF'],
	["Brad Pfahl", '56:13', 'DNF'],
];


// Configure MUI Table options
const options = {
  filterType: 'multiselect',
  count: 40,
  filter: false,
  pagination: false,
  print: false,
  responsive: 'standard',
  search: false,
  customToolbarSelect: () => {},
  selectableRows: false,
};

export default function Results() {
  const classes = useStyles();

  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: '#728f28',
        },
      },
	  MUIDataTableToolbar: {
		titleText: {
		  fontWeight: 'bold',
		}
      },
      // handles table data header color
	  MUIDataTableHeadCell: {
		fixedHeader: {
		  backgroundColor: '#b5d761',
		  fontWeight: 'bold',
		},
		data: {
		  fontWeight: 'bold',
		},
	  },
    }
   });

  const [year, setYear] = React.useState('2024');
  const [currentResultsData: string[], setCurrentResultsData] = React.useState(data2024);
  const [currentYearTitle: string, setCurrentYearTitle] = React.useState("2024 Results");
  const [currentColumnData, setCurrentColumnData] = React.useState(columns2024);
  const [show2024Summary, setShow2024Summary] = React.useState(true);
  const [show2023Summary, setShow2023Summary] = React.useState(false);
  const [show2022Summary, setShow2022Summary] = React.useState(false);
  const [show2021Summary, setShow2021Summary] = React.useState(false);

  const changeResultsView = (event: SelectChangeEvent) => {
	setYear(event.target.value);
	  if (event.target.value === 2024){
		  setShow2024Summary(true);
		  setShow2023Summary(false);
		  setShow2022Summary(false);
		  setShow2021Summary(false);
		  setCurrentYearTitle("2024 Results");
		  setCurrentResultsData(data2024);
		  setCurrentColumnData(columns2024);
	  }else if (event.target.value === 2023){
		  setShow2024Summary(false);
		  setShow2023Summary(true);
		  setShow2022Summary(false);
		  setShow2021Summary(false);
		  setCurrentYearTitle("2023 Results");
		setCurrentResultsData(data2023);
		setCurrentColumnData(columns2023);
	}else if (event.target.value === 2022){
		setCurrentYearTitle("2022 Results");
		  setShow2024Summary(false);
		setShow2023Summary(false);
		setShow2022Summary(true);
		setShow2021Summary(false);
		setCurrentResultsData(data2022);
		setCurrentColumnData(columns2022);
	}else{
		setCurrentYearTitle("2021 Results");
		  setShow2024Summary(false);
		setShow2023Summary(false);
		setShow2022Summary(false);
		setShow2021Summary(true);
		setCurrentResultsData(data);
		setCurrentColumnData(columns2021);
	}


  };

  return (
	<MuiThemeProvider theme={getMuiTheme}>
	<Grid container>
		<Grid item xs={12}>
			<Typography component="h2" variant="h4" className={classes.pageTitle} gutterBottom>
				Results
			</Typography>

			<Box sx={{ minWidth: 120 }}>
				<FormControl fullWidth className={classes.pageTitle}>
					<InputLabel id="select-year-label">Year</InputLabel>
					<Select
						labelId="select-year-label"
						id="select-year"
						value={year}
						label="Select Year"
						onChange={changeResultsView}
					>
						<MenuItem value={2024}>2024</MenuItem>
						<MenuItem value={2023}>2023</MenuItem>
						<MenuItem value={2022}>2022</MenuItem>
						<MenuItem value={2021}>2021</MenuItem>
					</Select>
				</FormControl>
			</Box>
			<br />
		</Grid>
		
		<Grid item xs={12}>

			<div>

				{show2024Summary && (
					<div>

						<Typography variant="h5" color="textPrimary">
							<strong>March 23rd, 2024 - The Fourth Annual BSBU</strong>
						</Typography>
						<br />
						<Typography variant="subtitle1">
							<strong>Race Summary</strong>
						</Typography>

						<Grid container alignItems="center" className={classes.metrics}>
							<ul>
								<li>Started on 3/23/2024 at 7:00 AM</li>
								<li>37 runners, 36 DNFs</li>
								<li>891 miles run in total</li>
								<li>30% of post-race survey participants reported PRs</li>
								<li>Concluded after 18 yards, the last person standing was <strong>Tommy Diehl</strong> with 75 miles run!</li>
								<li><Link href="https://ultrasignup.com/results_event.aspx?did=110022" color="primary" className={classes.raceReportLink} target="_BLANK"><strong>Ultrasignup Results Link</strong></Link></li>
							</ul>
						</Grid>
					</div>
				)}

				{show2023Summary && (
					<div>

						<Typography variant="h5" color="textPrimary">
							<strong>March 25th, 2023 - The Third Annual BSBU</strong>
						</Typography>
						<br />
						<Typography variant="subtitle1">
							<strong>Race Summary</strong>
						</Typography>

						<Grid container alignItems="center" className={classes.metrics}>
							<ul>
								<li>Started on 3/25/2023 at 7:00 AM</li>
								<li>34 runners, 33 DNFs</li>
								<li>1,070 group total miles run</li>
								<li>33% of post-race survey participants reported PRs</li>
								<li>Concluded after 19 yards, the last person standing was <strong>Ryan Horne</strong> with 79.23 miles run!</li>
								<li><Link href="https://ultrasignup.com/results_event.aspx?did=97742" color="primary" className={classes.raceReportLink} target="_BLANK"><strong>Ultrasignup Results Link</strong></Link></li>
								<li><Link href="/reports" color="primary" className={classes.raceReportLink}><strong>2023 Race Report by Mary</strong></Link></li>
							</ul>
						</Grid>
					</div>
				)}

				{show2022Summary && (
					<div>

						<Typography variant="h5" color="textPrimary">
							<strong>March 19th, 2022 - The Second Annual BSBU</strong>
						</Typography>
						<br />
						<Typography variant="subtitle1">
							<strong>Race Summary</strong>
						</Typography>

						<Grid container alignItems="center" className={classes.metrics}>
							<ul>
								<li>Started on 3/19/2022 at 7:00 AM</li>
								<li>37 runners, 36 DNFs</li>
								<li>675.54 group total miles run </li>
								<li>86.7% of post-race survey participants reported PRs</li>
								<li>Concluded after 14 yards, the last person standing was <strong>Aaron Bowers</strong> with 58.38 miles run!</li>
								<li><Link href="https://ultrasignup.com/results_event.aspx?did=92618" color="primary" className={classes.raceReportLink} target="_BLANK"><strong>Ultrasignup Results Link</strong></Link></li>
								<li><Link href="/reports" color="primary" className={classes.raceReportLink}><strong>2022 Race Report by Samkit</strong></Link></li>
							</ul>
						</Grid>
					</div>
				)}

				{show2021Summary && (
					<div>

						<Typography variant="h5" color="textPrimary">
							<strong>March 20th, 2021 - Inaugural running of The Bullshit Backyard Ultra</strong>
						</Typography>
						<br />
						<Typography variant="subtitle1">
							<strong>Race Summary</strong>
						</Typography>

						<Grid container alignItems="center" className={classes.metrics}>
							<ul>
								<li>Started on 3/20/2021 at 7:00 AM</li>
								<li>15 runners, 14 DNFs </li>
								<li>287.523 group total miles run </li>
								<li>10 personal bests</li>
								<li>Concluded after 11 yards, the last person standing was <strong>Ryan Horne</strong> with 45.83 miles run!</li>
								<li><Link href="https://ultrasignup.com/results_event.aspx?did=92597" color="primary" className={classes.raceReportLink} target="_BLANK"><strong>Ultrasignup Results Link</strong></Link></li>
								<li><Link href="/reports" color="primary" className={classes.raceReportLink}><strong>2021 Race Report by Matt</strong></Link></li>
							</ul>
						</Grid>
					</div>
				)}


			</div>
			<br />

			<MUIDataTable
			  title={currentYearTitle}
			  data={currentResultsData}
			  columns={currentColumnData}
			  options={options}
			/>

			<Typography variant="subtitle2">
				<br />
				<strong>Results Guide</strong>
				<ul>
					<li>Each <strong>Yard</strong> equates to running one loop of the course in an hour</li>
					<li>A <strong>Yard</strong> is <strong>4.167 miles</strong></li>
					<li><strong>[LOS]</strong> indicates the last one standing</li>
					<li>DNF indicates that the runner <strong>"Did Not Finish"</strong> the Yard within the time period, thus being disqualified from continuing the race</li>
					<li>* = Denotes a severe weather delay</li>
				</ul>
			</Typography>
		</Grid>
		
	</Grid>
	</MuiThemeProvider>
  );
}