import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';


function Copyright() {
  return (
    <Typography variant="body2" color="inherit" align="center">
      {'Copyright © '}
	  {new Date().getFullYear()}
	  {' '}
      <Link color="inherit" href="https://www.mattdevs.com/">
        mattdevs
      </Link>
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.default,
	color: theme.palette.primary.contrastText,
	borderTop: `5px solid ${theme.palette.primary.dark}`,
    padding: theme.spacing(2, 0),
  },
  siteMap: {
	color: theme.palette.primary.contrastText,
  }
}));

export default function Footer(props) {
  const classes = useStyles();
  const { description, title } = props;

  return (
    <footer className={classes.footer}>
		
		<Grid container spacing={3} alignItems="center" justify="space-evenly" direction="row">
			<Grid item xs={6} justify="center">
				<Typography color="secondary" variant="h6" align="center" gutterBottom>
				  {title}
				</Typography>

				<Typography variant="subtitle1" align="center">
				  <Link href="/about">About the race</Link><br />
				  <Link href="/course">Course information</Link><br />
				  <Link href="/history">A History of The Bullshit Backyard Ultra</Link><br />
				  <Link href="/reports">Race Reports</Link><br />
				  <Link href="/results">Race Results</Link><br />
				  <Link href="/photos">Photos from around the Yard</Link><br />
				</Typography>
				
				<br />
				
				<Copyright />
				<Typography variant="subtitle2" align="center">
				  {description}
				</Typography>
			</Grid>
		</Grid>
      
    </footer>
  );
}

Footer.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};