import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

// Import react grid gallery
import Gallery from "react-grid-gallery";

// Content imports
import GroupPhoto from './static/2021_group_pic.png'; 
import WinnerPhoto2021 from './static/2021_winner.png'; 

// Race Report Photos
import Samkit from './static/cheyenne_pics/2022/SamkitCrossing.jpg'; 
import Aaron from './static/cheyenne_pics/2022/AaronAtNight.jpg';
import Matt from './static/course_makers.png';
import Mary from './static/race_reports/Mary_post_race_2023.jpg';

export const PHOTOS =
[{
        src: GroupPhoto,
		thumbnail: GroupPhoto,
        thumbnailWidth: 320,
        caption: "Photo taken after the race concluded and before the sunset."
},
{
        src: WinnerPhoto2021,
		thumbnail: WinnerPhoto2021,
        thumbnailWidth: 320,
        caption: "Congratulations to our first Bullshit winner, Ryan Horne!"
},
]

const useStyles = makeStyles((theme) => ({
  pageTitle: {
	  borderBottom: `1px solid ${theme.palette.background.default}`,
  },
  groupPic: {
	  width: 500,
	  float: 'right',
  },
  winnerPic: {
	  width: 250,
	  float: 'left',
  }
}));

export default function RaceReports() {
  const classes = useStyles();

  return (
	<Grid item className={classes.results}>
		<Typography component="h2" variant="h4" className={classes.pageTitle} gutterBottom>
			Race Reports
		</Typography>
		
		<br />
		
		<Grid>

			<Typography	variant="h5" color="textPrimary" gutterBottom>
				March 25th, 2023 - BSBU #3
			</Typography>
			<Typography variant="subtitle2" color="textPrimary" gutterBottom>
				<img src={Mary} width="300px" /> <br />
				Written by <Link href="https://www.instagram.com/maryrunsultras/" target="_BLANK">Mary</Link>
			</Typography>

			<br />

			<Typography component="body1" color="textPrimary">
				Yesterday I did a Last Person Standing race that started at 7 am and we were to run 4.167 miles every
				hour on the hour until only one person was left running. We were running around a farm that was filled
				with mud and 💩 to navigate through. The field included 40 runners from all over the northeast. I was
				aiming for 50 miles which would be just about 12 loops, but assumed I would land somewhere between 31
				(my previous distance record) and the high 40s.
				<br /><br />
				The first few loops were raining and sleet-filled ice baths. It was absolutely the most fabulous thing
				I had ever run through and everybody else either hated it or felt the same. Several dropped out during
				the weather.
				<br /><br />
				I felt a surge of energy at the start of the middle loops (I have no idea which ones because I refused
				to look at my watch) where I started coming into camp before anybody else did, buying myself some time
				to hydrate, eat, change my shoes, and rest. This is about when people started cheering for me at the
				actual race, and it was absolutely exhilarating to hear my name yelled out.
				<br /><br />
				I was asked by one other person in the race, “Do you run ultras often?” And I answered him,
				“No, this is my second.” He was extremely supportive and extremely shocked at the same time lol.
				I got a lot of encouraging words from the people I was competing against. I made a few friends on
				the course. I had over 14 hours running with some of these people, so I learned a lot about them
				and it was pretty good bonding time, running around and beating the hell out of our bodies.
				<br /><br />
				Now, I’ll thank my husband who would help me every time I came to camp, giving me all the fuel I needed
				and forcing me to drink water. Folks, this man was changing my shoes and socks for me because I was too
				tired to do it. I absolutely couldn’t have gone as far as I did without him.
				<br /><br />
				By about 6 pm, my energy started to fade, shortly before I hit 50 miles. I decided when I went out on
				the 6 pm loop that it would be my last one. At this point there were 9 people left in the field, give
				or take, I honestly have a very hazy memory of this. I spent the loop basically thanking my body for
				the day that it gave me, and crying (😂) because I was so overjoyed with what I had accomplished. I
				came in about 10 minutes later than I did in the 5 previous loops. At 6:56 pm, I turned to the
				Race Directors wife (I think…again, hazy) and said ”I’m done”.
				<br /><br />
				This is where shit got super real. Theyyyyy were not done WITH me, and thought I could go a little
				further. Before I knew it, I had two or three guys coming around telling me how badly I needed to win
				it, how the other guys were just as tired as I was, I had people pulling for me, and I needed to start
				the night loop. Erik put on my night gear (lights and headlamp) and I was asked at 6:59 pm, ”Are you
				doing the night loop?” I honestly had no clue until about 20 seconds before it started. When 6:59:40
				hit, I was suddenly getting ready to hit the lap on my watch and either complete loop 13 or die trying.
				<br /><br />
				I started out with all the guys, getting used to the new loop we were running but then got overwhelmingly
				tired and slowed my roll. I fell about a half mile behind. I probably wasn’t looking very good anymore,
				but when I ran by the crowd the first time and heard all the music and cheering, and more specifically
				my name, I kicked it into gear. I spent the middle part of the loop not only catching up, but speeding
				by all the guys, the second time I passed the crowd, I heard a “that’s Mary…AND SHE’S IN THE LEAD! WAHOOOOO!!”
				Which, for context, absolutely doesn’t mean 💩 in a last man standing, but it was very motivating to hear
				from someone who I literally had no idea who he was. I finished the loop at 7:48, give or take a minute,
				rested, and started my next loop.
				<br /><br />
				Loop 14 wasn’t anything too special aside from me realizing that although I could literally put my body
				into the dirt and win this race, it wasn’t what I wanted to do. I could feel my heart and legs sending
				messages to my mind that it was so tired, and I absolutely wanted to respect my body. At the start of
				loop 15, I turned to everyone and said ”This is it, I’m going to get my mileage for my 100k and turn back.”
				<br /><br />
				I walked a lot of loop 15, chatting with the other guys and wishing them well, and as I approached the
				end of the road after getting my mileage in, I shut off my lights and walked towards my husband, gave
				him a hug, and took a shot of fireball given to me by a few men who doused their fire after I decided
				I wouldn’t continue the race (literally felt so honored that they stuck around for me to take a shot
				with them). I read through everybody’s texts that they sent me throughout the day, thanked the race
				director, and headed home, extremely content with my performance.
				<br /><br />
				I’ve never in my life had so many people cheering for me, rooting for me, and believing in me. It
				carried me through every ache I felt, and there were a LOT of aches.
				<br /><br />
				Today I feel like a small pile of garbage but honestly not too bad and I think it’s because I listened
				to my body over the ultimate desires of this really powerful crowd that I didn’t want to disappoint and
				inner desire to win it all. It was hard to quit with so many people rooting for me back home as well,
				but I knew that although there would be minor disappointment at camp, I think I made a good number of
				my friends and family extremely proud.
				<br /><br />
				The closest thing I could compare my current feeling to is childbirth. That’s the level of exhaustion
				in my body right now.
				<br /><br />
				And for the record, I did this on my period. So, I’ll end this with <strong>"Fuck yes, women."</strong>
				<br /><br />
			</Typography>

			
			<Typography variant="h5" color="textPrimary" gutterButtom>
				March 19th, 2022 - BSBU #2
			</Typography>
			<Typography variant="subtitle2" color="textSecondary" gutterButtom>
				<img src={Samkit} width="300px" /> <br />
				written by <Link href="https://www.instagram.com/samkit777/" target="_BLANK">Samkit</Link> 
			</Typography>

			<br />

			<Typography component="body1" color="textPrimary">
				
				Documenting my first trail ultra marathon run (50km distance for me) at The Bullshit Backyard Ultra 2022. 
About two weeks ago, I was fortunate enough to participate in a Backyard Ultra Running event. It was race organized and managed by my friend Matt Pfahl at his parent’s farm in Lenoxville, Pennsylvania. 
			<br /><br />
A Backyard Ultra is a Last Person standing format race where runners complete 4.1667 mile loop every hour. Everyone who completes the loop within the hour time lines up again at the top of the hour and runs another loop. The distance of 4.1667 calculates out to 100 Miles in 24 hours . Runners keep going every hour until there is only single runner left and completes the full loop. Everyone is given DNF(did not finish) and last person standing is declared the Winner.
			<br /><br />
Name of the event was The Bullshit Backyard Ultra and It was second annual running of this event. It was a technical course through out with lots of mud, tree roots, rocks, water clogged grass fields and the real bullshit from farm animals scattered through various sections. For someone like me, who is just about a year into running and trained mostly on paved surfaces, I was in for a shocking surprise when I went out for the first loop. 
			<br /><br />
Not truly seeing the course conditions before the race, I had casually set up a lofty goal in my mind which was to try to run the longest distance I ever ran before. After all, this is a type of event where one needs go with “an infinite mindset”, becsuse there is really no finish line or set distance for the race. For me, I wanted to try for anything greater than Marathon distance (42km / 26.2 miles). I wasn’t sure if 50km was going to be realistic goal but it was certainly on the cards if I felt good after my initial goal and got close enough. During the first loop, I immediately got hammered by all the muddy and wet trail elements. My mind was literally screaming craziness at the thoughts of slogging in muddy trails for more than couple hours. Towards the end of first loop, I was literally trying to convince myself to give up and come back another year with proper training on trails. 
			<br /><br />
However, after finishing loop 1 and 3-4 minutes of mental regrouping, I decided to go on loop 2. I started to settle down in the run both physically and mentally. Chatting with other runners during next couple of loops made things a bit more easier to pass by. I got my hydration and nutrition dialed in by loop3 and started to focus on maintaining my rhythm. Feeling much better, I told myself to not worry about the distance goals and just focus on finishing current loop within cut off time. Ended up finishing 6 loops / 25.5 miles before cut off time. Before 7th loop start,I lost some crucial time trying to deal with wet feet and missed taking planned nutrition and hydration. The miscue started showing up in loop 7 as my pace started to slow down and I fell behind almost everyone on the loop. At one point I was running alone and trying to follow trail markers. My mind was so tired and I unintentionally missed a small section within the loop. I was at mile 28 when I knew I was not going to finish loop 7 before the hour time limit. Race was over for me at that point but I still decided to keep running even at a reduced pace. I ultimately missed finishing 7th loop distance by ~4 minutes, but for some unknown reasons, I decided to continue running for another 2.5 miles/4km to hit my personal goal of running 50km distance. 
			<br /><br />
End of this fabulous day, I had a blast running with some awesome people on the farm for close to 8 hours. Truly enjoyed the whole experience of backyard and trail running. The best part for me was to meet and interact with some of the amazing runners, catch up with friends and witness some great endurance feats.
			<br /><br />
Big thanks to Matt for inviting me to this event and Pfahl family for hosting/managing this race. Super appreciate all the motivation and guidance in the months and weeks leading up to this race. Special kudos to my friend Anton for hosting a fundraiser and donation drive to Help Ukraine!
			<br /><br />
Apart fom running, It took quite a bit of logistical planning, preparation and a lot of driving in one weekend to make this event successful for me. It couldn’t be possible without the support of my love Hémãłí Dhõłákîà.
			<br /><br />
There are very few experiences in life that come close to running an ultramarathon distance in the heart of nature’s habitat. When you run your first Marathon, you realize what things you are capable of. When you run your first ultra marathon, you experience things that you never thought you were capable of, and you somehow figure out how to conquer them. 
Mind over Body.
			</Typography>
			
			<br />
			<br />
		</Grid>
		
		<br />
		
		
		<Grid>
			
			<Typography variant="h5" color="textPrimary" butterButtom>
				March 19th, 2022 - BSBU #2
			</Typography>
			<Typography variant="subtitle2" color="textSecondary" butterButtom>
				<img src={Aaron} width="300px" /> <br />
				written by Aaron (via <Link href="https://www.instagram.com/p/CcthK6VtvZt/?utm_source=ig_web_copy_link" target="_BLANK" color="primary"><strong>Instagram</strong></Link>)
			</Typography>

			<br />

			<Typography component="body1" color="textPrimary">
				Last month, I ran the Bullshit Backyard Ultra on a farm in Lenoxville, PA.
				<br /><br />
I am extremely happy to report that I was the last runner standing, covering 58.337 miles over 14 hours! This marks my first win ever in an ultramarathon.
<br /><br />
This time I was supported by my wife, little brother, mom, and stepdad. Huge thanks to everyone for the support and keeping us fueled and hydrated throughout the day. I am also extremely proud of Ben for setting a personal best of 16.668 miles with hardly any training!
<br /><br />
Additional thanks to Matt Pfahl and his entire family for organizing an incredible event. We had a wonderful time and hope to return again in the future!
<br /><br />
On the day, the weather ranged from the 50s up into the 70s. We had a couple small showers in the afternoon, including a thunderstorm, as we were entering the night loop. The course was semi-technical with a variety of terrain and elevation. There was a considerable amount of mud to contend with because of the rain and winter melt. Not to mention, a healthy spread of biological landmines. It’s not called the Bullshit Backyard for nothing.
<br /><br />
Between the mud, rain, and small stream crossings, keeping my feet dry was a huge challenge and priority. After the showers, it was nearly impossible to keep my feet dry for more than 5 minutes, which can spell disaster over the long term. The foot care kit that I built after the Summit Backyard Ultra was an absolutely invaluable resource. On top of that, I must have gone through nearly 10 pairs of socks over the whole event. This race was a fantastic testing ground for my foot care protocol.
<br /><br />
Up next, we are preparing for the Capital Backyard Ultra in Lorton, VA this coming May. This race is a silver ticket qualifier for the national championship taking place in the fall. There will undoubtedly be some serious competition as last year’s winner completed 237 miles!
<br /><br />
The superb quality race photos were taken by <Link href="https://www.instagram.com/cheyenneautumnpfahl/" target="_BLANK">@cheyenneautumnpfahl</Link>
<br /><br />
As always, thank you for following along! 
			</Typography>
			
			<br />
			<br />
		</Grid>
		
		
		<Grid>
			<Typography variant="h5" color="textPrimary" butterButtom>
				March 20th, 2021 - Inaugural running of The Bullshit Backyard Ultra
			</Typography>
			<Typography variant="subtitle2" color="textSecondary" butterButtom>
				<img src={Matt} width="300px" /> <br />
				written by Matt
			</Typography>

			<br />

			<Typography component="body1" color="textPrimary">
				At long last the inaugural running of The Bullshit Backyard Ultra is complete! <br /><br />

				The race itself was everything I'd hoped for. No one tested positive for COVID-19 and even better, the majority of the field was vaccinated. It was 20 degrees at the start of the race and probably close to 60 and sunny by the time it ended. Many runners exceeded their personal bests for distance. The cows didn't mind us on the course and even welcomed us with some fresh pies. Congratulations to our first winner, Ryan Horne for outlasting everyone in the field and going 46 miles (or 11 yards)!

				<br /><br />

				Thanks to everyone who helped make this happen. Looking forward to next years running of the Bullshit!
				
				<Gallery images={PHOTOS} enableImageSelection={false} />

				<br /><br /><br /><br /><br /><br /><br /><br />
			</Typography>
			
			<Typography component="body2" color="primary">
				<Link href="/results" color="primary"><strong>View 2021 Results</strong></Link>
			</Typography>
		</Grid>
	</Grid>
  );
}