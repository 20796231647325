// System imports
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Helmet } from 'react-helmet';

// Page imports
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Footer from './Footer';
import About from './About';
import Course from './Course';
import History from './History';
import Results from './Results';
import Photos from './Photos';
import RaceReports from './RaceReports';

// Content imports
import GroupPhoto from './static/2021_group_pic.png';
import RegistrationBanner from './static/2022_banner.jpg';
import CourseMakers from './static/course_makers.png';
import WinnerPhoto2021 from './static/2021_winner.png'; 
import SkullTree from './static/skull_tree.jpg';
import RaceResults2022 from './static/cheyenne_pics/2022/Logan2022.jpg';

// Styling
const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0),
	textAlign: 'justify',
  },
}));

// Routing content
const sections = [
    { title: 'RACE INFO', url: '/about' },
    { title: 'COURSE', url: '/course' },
	{ title: 'REPORTS', url: '/reports' },
    { title: 'HISTORY', url: '/history' },
    { title: 'RESULTS', url: '/results' },
    { title: 'PHOTOS', url: '/photos' },
];

const mainFeaturedPost = {
  title: 'BSBU 2025',
  description: "The fifth annual BSBU set for March 22nd, 2025.",
	image: RegistrationBanner,
  imgText: 'main image description',
  linkText: 'Race registration details can be found here.',
  url: '/about',
};

const featuredPosts = [
  {
    title: 'Race Reports',
    description: 'Check out the race reports written by runners who participated in each year of the BSBU.',
	image: GroupPhoto,
    imageText: 'Hello world',
	  linkText: 'Read the reports.',
	url: '/reports',
  },
   {
    title: 'Results',
    description: 'Official results from each year of the BSBU.',
    image: RaceResults2022,
    imageText: 'Image Text',
	   linkText: 'View the results.',
	url: '/results',
  },
  {
    title: 'How it all began',
    description: 'The origin story of The Bullshit Backyard Ultra.',
    image: CourseMakers,
    imageText: 'Image Text',
	  linkText: 'Read the history of the BSBU.',
	url: '/history',
  },
];

export default function Blog() {
  const classes = useStyles();

  const bsTheme = 
      createMuiTheme({
        palette: {
			primary: {
			  light: '#b5d761',
			  main: '#A3CD3A',
			  dark: '#728f28',
			  contrastText: '#FFFFFF',
			},
			secondary: {
			  light: '#ffd699',
			  main: '#ffcc80',
			  dark: '#b28e59',
			  contrastText: '#000',
			},
			background: {
				paper: '#f5f5f5',
				default: '#000',
			},
		  },
      });

  return (
	<ThemeProvider theme={bsTheme}>
	<Router>
		<React.Fragment>
			<Helmet>
				<title>The Bullshit Backyard Ultra</title>
			</Helmet>
		
		  <CssBaseline />
			
		  <Header title="THE BULLSHIT BACKYARD ULTRA" sections={sections} />
		  
		  <Grid className={classes.content}>
			  <Container>
				<main>
					<Switch>
						<Route path="/about">
							<About />
						</Route>
						
						<Route path="/course">
							<Course />
						</Route>
						
						<Route path="/history">
							<History />
						</Route>
						
						<Route path="/results">
							<Results />
						</Route>
						
						<Route path="/photos">
							<Photos />
						</Route>
						
						<Route path="/reports">
							<RaceReports />
						</Route>
						
						<Route path="/">
							<MainFeaturedPost post={mainFeaturedPost} />
							<Grid container spacing={4}>
								{featuredPosts.map((post) => (
									<FeaturedPost key={post.title} post={post} />
								))}
							</Grid>
						</Route>

					</Switch>
				</main>
			  </Container>
			</Grid>
		  <Footer title="THE BULLSHIT BACKYARD ULTRA" description="All rights reserved " />
		</React.Fragment>
	</Router>
	</ThemeProvider>
  );
}