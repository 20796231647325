import GroupPhoto from './static/2021_group_pic.png'; 
import WinnerPhoto2021 from './static/2021_winner.png'; 
import BradGazelle from './static/photo_gallery/BradGazzelleBullshit2021.jpg'; 
import AlmostThereGroup from './static/photo_gallery/AlmostThereBullshit2021.jpg'; 
import AlongTheCrick from './static/photo_gallery/AlongTheCrickBullshit2021-0002.jpg'; 
import BambooTrailWinter from './static/photo_gallery/bamboo_in_winter.jpg'; 
import TrailworkSawBull from './static/photo_gallery/coursework_bullpie_to_go.jpg'; 
import TrailworkManMeetsCow from './static/photo_gallery/coursework_man_meets_cow.jpg'; 
import SurpriseSnow from './static/photo_gallery/coursework_surprise_snow.jpg'; 
import GroupClimbing from './static/photo_gallery/GroupClimbingBullshit2021.jpg'; 
import LowerPasture from './static/photo_gallery/lower_pasture_sunset.jpg'; 
import ShooingTheHerd from './static/photo_gallery/ShoowingTheHerdBullshit2021.jpg'; 
import SkullAndCrew from './static/photo_gallery/SkullAndCrewBullshit2021.jpg'; 
import Medals from './static/awards/medal_load.jpg'; 
import DNFMedal from './static/awards/dnf_medal.jpg';

// Cheyenne Pics - 2021
import AntonRock from './static/cheyenne_pics/AntonAndTheRockBullshit2021.jpg'; 
import AtoddFinishing from './static/cheyenne_pics/AtoddFinishingLoopOneBullshit2021.jpg'; 
import BoisAndPies from './static/cheyenne_pics/BoisAndPiesBullshit2021.jpg';
import BradleyBullshit from './static/cheyenne_pics/BradleyBullshit2021.jpg';
import CowPastureGroup3 from './static/cheyenne_pics/CowPastureGroup3Bullshit2021.jpg'; 
import DonkeyOnTheHill from './static/cheyenne_pics/DonkeyOnTheHillBullshit2021.jpg';
import FinishingLoopOneGroup from './static/cheyenne_pics/FinishingLoopOneGroupBullshit2021.jpg';
import Greg from './static/cheyenne_pics/GregBullshit2021.jpg'; 
import GroupFarmscape1 from './static/cheyenne_pics/GroupFarmscape1Bullshit2021.jpg';
import GroupStartingLoopTwo from './static/cheyenne_pics/GroupStartingLoopTwoBullshit2021.jpg';
import LoopOneStyling from './static/cheyenne_pics/LoopOneStylingBullshit2021.jpg';
import PreRaceGroup from './static/cheyenne_pics/PreRaceGroupBullshit2021.jpg';
import RaceResults from './static/cheyenne_pics/RaceResults2021.jpg';
import SmilingRyan from './static/cheyenne_pics/SmilingRyanBullshit2021.jpg'; 

// Cheyenne Pics - 2022
import AaronAtNight from './static/cheyenne_pics/2022/AaronAtNight.jpg'; 
import AaronInAction from './static/cheyenne_pics/2022/AaronInAction.jpg'; 
import AlongTheCreekTyler from './static/cheyenne_pics/2022/AlongTheCreekTyler.jpg'; 
import AntonDNF from './static/cheyenne_pics/2022/AntonDNF.jpg'; 
import AntonUkraine from './static/cheyenne_pics/2022/AntonUkraine.jpg'; 
import BrianDNF from './static/cheyenne_pics/2022/BrianDNF.jpg'; 
import BryceHydroshell from './static/cheyenne_pics/2022/BryceHydroshell.jpg'; 
import ChelseaDNF from './static/cheyenne_pics/2022/ChelseaDNF.jpg'; 
import ChelseyFinish from './static/cheyenne_pics/2022/ChelseyFinish.jpg'; 
import DanBanana from './static/cheyenne_pics/2022/DanBanana.jpg'; 
import Downhill from './static/cheyenne_pics/2022/Downhill.jpg'; 
import ExitingTrees from './static/cheyenne_pics/2022/ExitingTrees.jpg'; 
import GregStrong from './static/cheyenne_pics/2022/GregStrong.jpg'; 
import JohnDNF from './static/cheyenne_pics/2022/JohnDNF.jpg'; 
import KishDNF from './static/cheyenne_pics/2022/KishDNF.jpg'; 
import LizAndRichDNF from './static/cheyenne_pics/2022/LizAndRichDNF.jpg'; 
import LizHi from './static/cheyenne_pics/2022/LizHi.jpg'; 
import Logan2022 from './static/cheyenne_pics/2022/Logan2022.jpg'; 
import MadyAndBrad from './static/cheyenne_pics/2022/MadyAndBrad.jpg'; 
import MadyDNF from './static/cheyenne_pics/2022/MadyDNF.jpg'; 
import MattAtNight from './static/cheyenne_pics/2022/MattAtNight.jpg'; 
import MistyHayField from './static/cheyenne_pics/2022/MistyHayField.jpg';
import PreRace2022 from './static/cheyenne_pics/2022/PreRace2022.jpg'; 
import PreRaceGroup2022 from './static/cheyenne_pics/2022/PreRaceGroup2022.jpg'; 
import RyanAndTrees from './static/cheyenne_pics/2022/RyanAndTrees.jpg'; 
import RyanPhotogenic from './static/cheyenne_pics/2022/RyanPhotogenic.jpg'; 
import SamkitCrossing from './static/cheyenne_pics/2022/SamkitCrossing.jpg'; 
import StartingGroup from './static/cheyenne_pics/2022/StartingGroup.jpg'; 
import Sycamores from './static/cheyenne_pics/2022/Sycamores.jpg'; 
import TankAlley from './static/cheyenne_pics/2022/TankAlley.jpg'; 
import TheBoysPosing from './static/cheyenne_pics/2022/TheBoysPosing.jpg'; 
import ThePintoClan from './static/cheyenne_pics/2022/ThePintoClan.jpg'; 
import TopPastureGroup from './static/cheyenne_pics/2022/TopPastureGroup.jpg'; 
import TopThree from './static/cheyenne_pics/2022/TopThree.jpg'; 

// 2023 Pics
import Checkin2023 from './static/2023/checkin_2023.jpg';
import Kickoff2023 from './static/2023/kickoff_2023.jpg';
import EarlyLoop2023 from './static/2023/off_for_another_loop_2023.jpg';
import MuddyCorral2023 from './static/2023/corral_of_mud_2023.jpg';
import Hail2023 from './static/2023/hail_2023.jpg';
import MuddyCrossing2023 from './static/2023/muddy_crossing_2023.jpg';
import Creekside2023 from './static/2023/burnside_2023.jpg';
import DanBananaBamboo2023 from './static/2023/dan_banana_bamboo_2023.jpg';
import Ramp2023 from './static/2023/aluminum_ramp_2023.jpg';
import BrianGoPro2023 from './static/2023/brian_gopro_2023.jpg';
import HappyFinisher2023 from './static/2023/happy_finisher_2023.jpg';
import Krysten2023 from './static/2023/krysten_2023.jpg';
import MaryHayfield2023 from './static/2023/mary_hayfield_2023.jpg';
import Bryce2023 from './static/2023/bryce_2023.jpg';
import DanAtNight2023 from './static/2023/dan_at_night_2023.jpg';
import Why2023 from './static/2023/why_are_we_doing_this_2023.jpg';
import MaryAndDan2023 from './static/2023/mary_and_dan_2023.jpg';
import PintoClan2023 from './static/2023/pinto_clan_2023.jpg';
import NightLoopBattle2023 from './static/2023/night_loop_battle_2023.jpeg';
import AlecCrew2023 from './static/2023/alec_and_crew_2023.jpeg';
import LosAndAssist2023 from './static/2023/los_and_assist_2023.jpeg';

// 2024 Pics
import Aaron2024 from './static/2024/2024_aaron.jpg';
import Assist2024 from './static/2024/2024_assist.JPG';
import Atodd2024 from './static/2024/2024_atodd.jpg';
import Bill2024 from './static/2024/2024_bill.jpg';
import Canada2024 from './static/2024/2024_canada.jpg';
import Corral2024 from './static/2024/2024_corral.jpg';
import Downhill2024 from './static/2024/2024_downhill.jpg';
import Field2024 from './static/2024/2024_field.jpg';
import Logan2024 from './static/2024/2024_logan.jpg';
import Mary2024 from './static/2024/2024_mary.jpg';
import Night2024 from './static/2024/2024_night.jpg';
import RaceStart2024 from './static/2024/2024_racestart.jpg';
import RyanBridge2024 from './static/2024/2024_ryanbridge.jpg';
import Three2024 from './static/2024/2024_three.JPG';
import Tommy2024 from './static/2024/2024_tommy.jpg';
import Woodchips2024 from './static/2024/2024_woodchips.jpg';
import Yard82024 from './static/2024/2024_yard8.jpg';

export const IMAGES =
[
    {
        src: RaceStart2024,
        thumbnail: RaceStart2024,
        thumbnailWidth: 320,
        caption: "2024 - Pre-race howl in the freezing rain to get things going."
    },
    {
        src: RyanBridge2024,
        thumbnail: RyanBridge2024,
        thumbnailWidth: 320,
        caption: "2024 - Runners cross the bridge near the bridge."
    },
    {
        src: Corral2024,
        thumbnail: Corral2024,
        thumbnailWidth: 320,
        caption: "2024 - Heading out for another yard."
    },
    {
        src: Atodd2024,
        thumbnail: Atodd2024,
        thumbnailWidth: 320,
        caption: "2024 - Another please."
    },
    {
        src: Woodchips2024,
        thumbnail: Woodchips2024,
        thumbnailWidth: 320,
        caption: "2024 - Woodchips can only soak up so much water."
    },
    {
        src: Logan2024,
        thumbnail: Logan2024,
        thumbnailWidth: 320,
        caption: "2024 - Our youngest runner to complete at least 1 full loop!"
    },
    {
        src: Canada2024,
        thumbnail: Canada2024,
        thumbnailWidth: 320,
        caption: "2024 - Our first international competitor hailing from Canada!"
    },
    {
        src: Bill2024,
        thumbnail: Bill2024,
        thumbnailWidth: 320,
        caption: "2024 - Definitely the muddiest year so far."
    },
    {
        src: Aaron2024,
        thumbnail: Aaron2024,
        thumbnailWidth: 320,
        caption: "2024 - One of the nicer sections this year."
    },
    {
        src: Yard82024,
        thumbnail: Yard82024,
        thumbnailWidth: 320,
        caption: "2024 - Yard number 8."
    },
    {
        src: Downhill2024,
        thumbnail: Downhill2024,
        thumbnailWidth: 320,
        caption: "2024 - Stretch your legs out on this nice long downhill."
    },
    {
        src: Three2024,
        thumbnail: Three2024,
        thumbnailWidth: 320,
        caption: "2024 - And then there were three."
    },
    {
        src: Night2024,
        thumbnail: Night2024,
        thumbnailWidth: 320,
        caption: "2024 - Fastest section of the course, for sure."
    },
    {
        src: Assist2024,
        thumbnail: Assist2024,
        thumbnailWidth: 320,
        caption: "2024 - Mary with the Assist!"
    },
    {
        src: Tommy2024,
        thumbnail: Tommy2024,
        thumbnailWidth: 320,
        caption: "2024 - Last person standing, Tommy, enjoying himself on the course!"
    },
    {
        src: Checkin2023,
        thumbnail: Checkin2023,
        thumbnailWidth: 320,
        caption: "2023 - Runners check in at the barn port as the rain softens up the course."
    },
    {
        src: Kickoff2023,
        thumbnail: Kickoff2023,
        thumbnailWidth: 320,
        caption: "2023 - Runners take part in a pre-race howl."
    },
    {
        src: EarlyLoop2023,
        thumbnail: EarlyLoop2023,
        thumbnailWidth: 320,
        caption: "2023 - Heading out on one of the early loops!"
    },
    {
        src: MuddyCorral2023,
        thumbnail: MuddyCorral2023,
        thumbnailWidth: 320,
        caption: "2023 - It wasn't long before the corral turned into a mud pit!"
    },
    {
        src: Hail2023,
        thumbnail: Hail2023,
        thumbnailWidth: 320,
        caption: "2023 - Our first experience with hail at the BSBU!"
    },
    {
        src: MuddyCrossing2023,
        thumbnail: MuddyCrossing2023,
        thumbnailWidth: 320,
        caption: "2023 - During the later loops this area was extremely muddy."
    },
    {
        src: Creekside2023,
        thumbnail: Creekside2023,
        thumbnailWidth: 320,
        caption: "2023 - Running along the Tunkhannock Creek."
    },
    {
        src: DanBananaBamboo2023,
        thumbnail: DanBananaBamboo2023,
        thumbnailWidth: 320,
        caption: "2023 - Dan and Banana and Bamboo."
    },
    {
        src: Ramp2023,
        thumbnail: Ramp2023,
        thumbnailWidth: 320,
        caption: "2023 - The treacherous aluminum ramp."
    },
    {
        src: BrianGoPro2023,
        thumbnail: BrianGoPro2023,
        thumbnailWidth: 320,
        caption: "2023 - Brian taking a gopro video while being photographed. Thanks Brian!"
    },
    {
        src: HappyFinisher2023,
        thumbnail: HappyFinisher2023,
        thumbnailWidth: 320,
        caption: "2023 - Most finishers are happy to be done."
    },
    {
        src: Krysten2023,
        thumbnail: Krysten2023,
        thumbnailWidth: 320,
        caption: "2023 - Major kudos to the real race director!"
    },
    {
        src: MaryHayfield2023,
        thumbnail: MaryHayfield2023,
        thumbnailWidth: 320,
        caption: "2023 - Mary makes her way through the hayfield."
    },
    {
        src: Bryce2023,
        thumbnail: Bryce2023,
        thumbnailWidth: 320,
        caption: "2023 - A personal best and then some for Bryce!"
    },
    {
        src: DanAtNight2023,
        thumbnail: DanAtNight2023,
        thumbnailWidth: 320,
        caption: "2023 - Dan running on the road section during the night loops."
    },
    {
        src: Why2023,
        thumbnail: Why2023,
        thumbnailWidth: 320,
        caption: "2023 - Starting yet another night loop."
    },
    {
        src: MaryAndDan2023,
        thumbnail: MaryAndDan2023,
        thumbnailWidth: 320,
        caption: "2023 - Making their way along the night loop."
    },
    {
        src: PintoClan2023,
        thumbnail: PintoClan2023,
        thumbnailWidth: 320,
        caption: "2023 - A family affair! The Pintos have their most successful BSBU yet!"
    },
    {
        src: NightLoopBattle2023,
        thumbnail: NightLoopBattle2023,
        thumbnailWidth: 320,
        caption: "2023 - Ryan and Alec are the last two runners remaining in the early hours of Sunday."
    },
    {
        src: AlecCrew2023,
        thumbnail: AlecCrew2023,
        thumbnailWidth: 320,
        caption: "2023 - Alec celebrating the assist with his wonderful crew!"
    },
    {
        src: LosAndAssist2023,
        thumbnail: LosAndAssist2023,
        thumbnailWidth: 320,
        caption: "2023 - Ryan becomes the first two-time BSBU champion with the assist going to Alec!"
    },
    {
        src: PreRace2022,
		thumbnail: PreRace2022,
        thumbnailWidth: 320,
        caption: "2022 - Pre-Race briefing"
},
{
        src: PreRaceGroup2022,
		thumbnail: PreRaceGroup2022,
        thumbnailWidth: 320,
        caption: "2022 - No pre-race nerves to be seen!"
},
{
        src: AntonUkraine,
		thumbnail: AntonUkraine,
        thumbnailWidth: 320,
        caption: "2022 - Anton briefs the runners on raising money for those affected by the war in Ukraine. The biggest donor won a roll of toilet paper with Putin's face on it."
},
{
        src: StartingGroup,
		thumbnail: StartingGroup,
        thumbnailWidth: 320,
        caption: "2022 - Runners head out at the beginning of a loop."
},
{
        src: TankAlley,
		thumbnail: TankAlley,
        thumbnailWidth: 320,
        caption: "2022 - Runners ascending Tank Alley."
},
{
        src: Sycamores,
		thumbnail: Sycamores,
        thumbnailWidth: 320,
        caption: "2022 - Runners look small compared to the Sycamores."
},
{
        src: TheBoysPosing,
		thumbnail: TheBoysPosing,
        thumbnailWidth: 320,
        caption: "2022 - They do this every year now."
},
{
        src: TopPastureGroup,
		thumbnail: TopPastureGroup,
        thumbnailWidth: 320,
        caption: "2022 - Runners crossing the top of the pasture."
},
{
        src: AaronInAction,
		thumbnail: AaronInAction,
        thumbnailWidth: 320,
        caption: "2022 - Running along the Tunkhannock Creek."
},
{
        src: AlongTheCreekTyler,
		thumbnail: AlongTheCreekTyler,
        thumbnailWidth: 320,
        caption: "2022 - More runners enjoying the Tunkhannock Creek."
},
{
        src: DanBanana,
		thumbnail: DanBanana,
        thumbnailWidth: 320,
        caption: "2022 - A runner's best friend."
},
{
        src: Downhill,
		thumbnail: Downhill,
        thumbnailWidth: 320,
        caption: "2022 - The beginning of a long downhill section."
},
{
        src: ExitingTrees,
		thumbnail: ExitingTrees,
        thumbnailWidth: 320,
        caption: "2022 - Runners emerge from the horshoe trail at the top of the pasture."
},
{
        src: GregStrong,
		thumbnail: GregStrong,
        thumbnailWidth: 320,
        caption: "2022 - Greg is feeling confident."
},
{
        src: LizHi,
		thumbnail: LizHi,
        thumbnailWidth: 320,
        caption: "2022 - Liz says Hi!"
},
{
        src: MistyHayField,
		thumbnail: MistyHayField,
        thumbnailWidth: 320,
        caption: "2022 - Misty miles."
},
{
        src: RyanAndTrees,
		thumbnail: RyanAndTrees,
        thumbnailWidth: 320,
        caption: "2022 - Ryan is focused."
},
{
        src: RyanPhotogenic,
		thumbnail: RyanPhotogenic,
        thumbnailWidth: 320,
        caption: "2022 - Ryan is having fun."
},
{
        src: SamkitCrossing,
		thumbnail: SamkitCrossing,
        thumbnailWidth: 320,
        caption: "2022 - Samkit flashes a smile as he crosses the aluminim bridge."
},
{
        src: MadyAndBrad,
		thumbnail: MadyAndBrad,
        thumbnailWidth: 320,
        caption: "2022 - Enjoying the junkyard trail."
},
{
        src: Logan2022,
		thumbnail: Logan2022,
        thumbnailWidth: 320,
        caption: "2022 - The future of the BSBU."
},
{
        src: MadyDNF,
		thumbnail: MadyDNF,
        thumbnailWidth: 320,
        caption: "2022 - Almost completed the loop within time!"
},
{
        src: BrianDNF,
		thumbnail: BrianDNF,
        thumbnailWidth: 320,
        caption: "2022 - Still smiling!"
},
{
        src: BryceHydroshell,
		thumbnail: BryceHydroshell,
        thumbnailWidth: 320,
        caption: "2022 - 2nd BSBU in the books!"
},
{
        src: ChelseaDNF,
		thumbnail: ChelseaDNF,
        thumbnailWidth: 320,
        caption: "2022 - Another happy (did not)finisher!"
},
{
        src: ChelseyFinish,
		thumbnail: ChelseyFinish,
        thumbnailWidth: 320,
        caption: "2022 - Overjoyed to complete a loop at the BSBU!"
},
{
        src: JohnDNF,
		thumbnail: JohnDNF,
        thumbnailWidth: 320,
        caption: "2022 - One of many dark horse runners who went much further than expected this year."
},
{
        src: KishDNF,
		thumbnail: KishDNF,
        thumbnailWidth: 320,
        caption: "2022 - Don't ask this guy about computers or social media."
},
{
        src: LizAndRichDNF,
		thumbnail: LizAndRichDNF,
        thumbnailWidth: 320,
        caption: "2022 - The couple with the most yards completed this year!"
},
{
        src: ThePintoClan,
		thumbnail: ThePintoClan,
        thumbnailWidth: 320,
        caption: "2022 - The Pinto Clan had a tremendous showing this year. Father and three sons all running together!"
},
{
        src: AntonDNF,
		thumbnail: AntonDNF,
        thumbnailWidth: 320,
        caption: "2022 - Anton finishes after final day loop."
},
{
        src: MattAtNight,
		thumbnail: MattAtNight,
        thumbnailWidth: 320,
        caption: "2022 - Finally some night running, unlucky to get rain and storms along with it."
},
{
        src: AaronAtNight,
		thumbnail: AaronAtNight,
        thumbnailWidth: 320,
        caption: "2022 - This year's winner clocking some fast miles on a wet and muddy night loop."
},
{
        src: TopThree,
		thumbnail: TopThree,
        thumbnailWidth: 320,
        caption: "2022 - The top three finishers of the 2022 edition of the BSBU."
},
{
        src: PreRaceGroup,
		thumbnail: PreRaceGroup,
        thumbnailWidth: 320,
        caption: "2021 - Runners prepare to start the inaugural BSBU"
},
{
        src: AntonRock,
		thumbnail: AntonRock,
        thumbnailWidth: 320,
        caption: "2021 - Anton and the starting rock"
},
{
        src: AtoddFinishing,
		thumbnail: AtoddFinishing,
        thumbnailWidth: 320,
        caption: "2021 - Runners coming in to finishing a loop"
},
{
        src: BoisAndPies,
		thumbnail: BoisAndPies,
        thumbnailWidth: 320,
        caption: "2021 - There is no prize for most pies stepped in"
},
{
        src: BradleyBullshit,
		thumbnail: BradleyBullshit,
        thumbnailWidth: 320,
        caption: "2021 - It was 20 degrees F at the beginning of the race."
},
{
        src: CowPastureGroup3,
		thumbnail: CowPastureGroup3,
        thumbnailWidth: 320,
        caption: "2021 - The majority of the cow pies are located here"
},
{
        src: DonkeyOnTheHill,
		thumbnail: DonkeyOnTheHill,
        thumbnailWidth: 320,
        caption: "2021 - Beware the Donkey. He is the guardian of the herd."
},
{
        src: FinishingLoopOneGroup,
		thumbnail: FinishingLoopOneGroup,
        thumbnailWidth: 320,
        caption: "2021 - Chelsea contemplates going out for another loop"
},
{
        src: Greg,
		thumbnail: Greg,
        thumbnailWidth: 320,
        caption: "2021 - There's a bit of an uphill before the end of the loop"
},
{
        src: GroupFarmscape1,
		thumbnail: GroupFarmscape1,
        thumbnailWidth: 320,
        caption: "2021 - Runners exiting Red Hawk Down trail into the hayfield"
},
{
        src: GroupStartingLoopTwo,
		thumbnail: GroupStartingLoopTwo,
        thumbnailWidth: 320,
        caption: "2021 - Runners heading out on loop two"
},
{
        src: LoopOneStyling,
		thumbnail: LoopOneStyling,
        thumbnailWidth: 320,
        caption: "2021 - Yep"
},
{
        src: AlmostThereGroup,
		thumbnail: AlmostThereGroup,
        thumbnailWidth: 300,
        caption: "2021 - Runners crossing the stream to the stonewall loop (via GoPro)"
},
{
        src: AlongTheCrick,
		thumbnail: AlongTheCrick,
        thumbnailWidth: 300,
        caption: "2021 - Running along the Crick on Yard One (via GoPro)"
},
{
        src: GroupClimbing,
		thumbnail: GroupClimbing,
        thumbnailWidth: 300,
        caption: "2021 - Group running along the stone wall loop, taken with the (via GoPro)"
},
{
        src: BambooTrailWinter,
		thumbnail: BambooTrailWinter,
        thumbnailWidth: 300,
        caption: "Coursework 2021 - What the Bamboo Trail looks like in Winter"
},
{
        src: BradGazelle,
		thumbnail: BradGazelle,
        thumbnailWidth: 320,
        caption: "2021 - Runners crossing the stream to the stonewall loop (via GoPro)"
},
{
        src: SmilingRyan,
		thumbnail: SmilingRyan,
        thumbnailWidth: 320,
        caption: "2021 - Ryan's frosty beard"
},
{
        src: TrailworkSawBull,
		thumbnail: TrailworkSawBull,
        thumbnailWidth: 300,
        caption: "Coursework 2021 - Hard at work on the finer details of the course"
},
{
        src: TrailworkManMeetsCow,
		thumbnail: TrailworkManMeetsCow,
        thumbnailWidth: 300,
        caption: "Coursework 2021 - They are very gentle and often curious creatures"
},
{
        src: SurpriseSnow,
		thumbnail: SurpriseSnow,
        thumbnailWidth: 300,
        caption: "Coursework 2021 - Got a surprise flurry a few days before the race"
},
{
        src: LowerPasture,
		thumbnail: LowerPasture,
        thumbnailWidth: 300,
        caption: "Coursework 2021 - Shot taken from the lower pasture around sunset during some trail work"
},
{
        src: ShooingTheHerd,
		thumbnail: ShooingTheHerd,
        thumbnailWidth: 300,
        caption: "2021 - Of course they were in an unexpected location on Yard 1 (via GoPro)"
},
{
        src: SkullAndCrew,
		thumbnail: SkullAndCrew,
        thumbnailWidth: 300,
        caption: "2021 - Runners approaching the skull (via GoPro)"
},
{
        src: GroupPhoto,
		thumbnail: GroupPhoto,
        thumbnailWidth: 320,
        caption: "2021 - Photo taken after the race concluded and before the sunset"
},
{
        src: WinnerPhoto2021,
		thumbnail: WinnerPhoto2021,
        thumbnailHeight: 320,
        caption: "2021 - After running 46 miles, Ryan Horne was the last person standing!"
},
{
        src: RaceResults,
		thumbnail: RaceResults,
        thumbnailWidth: 320,
        caption: "2021 - Our volunteer time keepers show off the official results!"
},
{
        src: Medals,
		thumbnail: Medals,
        thumbnailWidth: 320,
        caption: "2021 - Custom Medals created by Ragged Cuts"
},
{
        src: DNFMedal,
		thumbnail: DNFMedal,
        thumbnailWidth: 320,
        caption: "2021 - DNF Medal created by Ragged Cuts"
},
]