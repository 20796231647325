import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

// Import images
import BullshitLogoText from './static/full_logo_header.png';
import BullshitLogo from './static/THEBULLLOGO.png';
import cursorImage from './static/bsbu_cursor_transparent.png';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
	borderTop: `2px dotted ${theme.palette.primary.light}`,
	color: theme.palette.primary.contrastText,
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
	color: theme.palette.primary.contrastText,
	fontWeight: 'bold',
  },
  header: {
	backgroundColor: theme.palette.background.default,
	borderBottom: `5px solid ${theme.palette.primary.dark}`,
	  cursor: `url(${cursorImage}), auto`,
  },

}));

export default function Header(props) {
  const classes = useStyles();
  const { sections, title } = props;

  return (
    <React.Fragment>
		<div className={classes.header}>
			 <Toolbar className={classes.toolbar}>
				<Typography
				  component="h2"
				  variant="h4"
				  color="primary"
				  align="center"
				  noWrap
				  className={classes.toolbarTitle}
				>
				<Link href="/">
					<img alt="Bullshit Logo" height="140" src={BullshitLogo} />
					<img alt="Bullshit Logo Text" width="200" src={BullshitLogoText} />
				</Link>
				</Typography>
			  </Toolbar>
		 
		  <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
			{sections.map((section) => (
			  <Link
				noWrap
				key={section.title}
				variant="dense"
				href={section.url}
				className={classes.toolbarLink}
			  >
			  {section.title}
			  </Link>
			))}
		  </Toolbar>
		</div>
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};