import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

// Import react grid gallery
import Gallery from "react-grid-gallery";

// Images
import Creek from './static/history/ScoutingAlongTheCreek.jpg';
import Bullpen from './static/history/ScoutingBullpen.jpg';
import CreekCrossing from './static/history/ScoutingCrickCrossing.jpg';
import Hayfield from './static/history/ScoutingHayfield.jpg';
import SnowBamboo from './static/history/SnowBamboo.jpg';
import ColdCows from './static/history/ColdCows.jpg';
import TreeDown from './static/history/TreeDown.jpg';
import ManMeetsCow from './static/history/ManMeetsCow.jpg';
import Flurries from './static/history/Flurries.jpg';
import CourseBros from './static/history/CourseBros.png';

export const SCOUTING =
[{
        src: Creek,
		thumbnail: Creek,
        thumbnailWidth: 320,
        caption: "Scouting: Along the Tunkhannock Creek."
},
{
        src: Bullpen,
		thumbnail: Bullpen,
        thumbnailWidth: 320,
        caption: "Scouting: The herd abides."
},
{
        src: CreekCrossing,
		thumbnail: CreekCrossing,
        thumbnailWidth: 320,
        caption: "Scouting: In the first loop of the inaugural BSBU these rocks are covered in ice, and I end up going into the water."
},
{
        src: Hayfield,
		thumbnail: Hayfield,
        thumbnailWidth: 320,
        caption: "Scouting: A beautiful Sycamore tree in the middle of the hayfield."
},
]

export const WINTER =
[{
        src: SnowBamboo,
		thumbnail: SnowBamboo,
        thumbnailWidth: 320,
        caption: "Trailwork in Winter: The bamboo forest was pretty in winter."
},
{
        src: ColdCows,
		thumbnail: ColdCows,
        thumbnailWidth: 320,
        caption: "Trailwork in Winter: The cows were probably laughing at me."
},
]

export const FINAL =
[{
        src: CourseBros,
		thumbnail: CourseBros,
        thumbnailWidth: 320,
        caption: "Final Stretch: We spent way too much effort leading up to the race building the course, we paid for it the day of the race."
},
{
        src: Flurries,
		thumbnail: Flurries,
        thumbnailWidth: 320,
        caption: "Final Stretch: We got some flurries in the days leading up to the race, which were unwelcome."
},
{
        src: ManMeetsCow,
		thumbnail: ManMeetsCow,
        thumbnailWidth: 320,
        caption: "Final Stretch: The cows got more comfortable with me over time."
},
{
        src: TreeDown,
		thumbnail: TreeDown,
        thumbnailWidth: 320,
        caption: "Final Stretch: The tree that came down the day before the race as I was making the final walk-thru."
},
]

const useStyles = makeStyles((theme) => ({
  pageTitle: {
	  borderBottom: `1px solid ${theme.palette.background.default}`,
  },
}));

export default function History() {
  const classes = useStyles();

  return (
	<Grid container>
	<Grid item className={classes.history} xs={12}>
		<Typography component="h2" variant="h4" className={classes.pageTitle} gutterBottom>
			A History of The Bullshit Backyard Ultra
		</Typography>
		
		<Typography variant="subtitle3">
			<em>According to Matt</em>
		</Typography>
		
		<br /><br />
		
		<Typography variant="subtitle1">
			<strong>An opportunity to create</strong>
		</Typography>
			
		<Typography component="body2">
			It all started a few miles down the road from the farm, across the Tunkhannock Creek, and up the hill about a quarter mile at the homestead where I grew up. This is where my dad and I spent the spring and summer building a trail system. The trails there are very technical and you can get almost 400ft in a single mile. While running the Pauchaug 50km in October, I got to thinking about the trails I had created and the idea to hold a backyard race wormed its way into my brain and took root. A conversation with my good friend Anton after the race made it official. We would hold a backyard ultra just like Laz, but it would be in my backyard. The old homestead wouldn't work for this format of course, >1000ft per yard breaks people too fast. However, my grandfather's farm was probably just big enough for a 4 mile loop and had a diversity of terrain that would be pleasant to run through. My grandfather passed away in 2009 and left the farm to my parents. He told my brother once that his favorite place on Earth was that farm. 
			
			<br /><br />
		</Typography>
		
		<Typography variant="subtitle1">
			<strong>Initial scouting</strong>
		</Typography>
		
		<Typography component="body2">
			My parents have lived on my grandfather's farm for over a decade at this point, so when it came time to scout the property my dad was intimately familiar with it. We also had the benefit of having just completed the trail system on the old homestead, so we had a pretty good system worked out. We plotted logical paths through the fields to maximize the distance and keep repeated sections at a minimum. When we took the course into the woods we sought out the game trails or cow paths to reduce the amount of clearing that needed to be done. This initial scouting took place at the end of December, 2020. Only three months to go until race day!
			<br /><br />
			
			
			<Gallery images={SCOUTING} enableImageSelection={false} />
			<br /><br />
			<br /><br />
			<br /><br />
			<br /><br />
		</Typography>
	</Grid>
	<Grid item className={classes.history} xs={12}>	
		<Typography variant="subtitle1">
			<strong>Trail work during winter</strong>
		</Typography>
		
		<Typography component="body2">
			The snow came on heavy in January and February. Things looked dire after a particularly frustrating weekend of trying to cut trail in a foot and a half of snow. A failed attempt at running the course in under an hour in these conditions warranted thoughts of postponement, but I held out hope that we'd get a melt in time to prep the remainder of the course. Every weekend that we could, we came back to PA to see family and of course, work on the trail. My brothers pitched in and helped out when they could as well, so there was a decent amount of clearing done before March.  
			
			<Gallery images={WINTER} enableImageSelection={false} />
			<br /><br />
			<br /><br />
			<br /><br />
			<br /><br />
		</Typography>
	</Grid>
	<Grid item className={classes.history} xs={12}>		
		<Typography variant="subtitle1">
			<strong>Logistics and Race Prep</strong>
		</Typography>
		
		<Typography component="body2">
			We started a Telegram group chat by the end of January and settled on a field of around 15 friends and family. My sister, Cheyenne, offered to donate her expertise and time as our official race photog and I got to work on making this a legitimate ultra event with the requisite backyard rules and regs (not to mention all the COVID-19 precautions).
				
			<br /><br />
		</Typography>
		
		<Typography variant="subtitle1">
			<strong>The final stretch</strong>
		</Typography>
		
		<Typography component="body2">		
			With a week to go, we got the melt we needed in order to organize a big trail clearing day. Folks who were running the race the next weekend showed up to help clear the trail (and get a bit of a preview). We walked the course with chainsaws, leaf blowers, and weed whackers from dawn til dusk that day. That wasn't the end of the work though. For the next week leading up to the race Anton and I were pretty much flat out getting everything ready for the big day. A day before the race, we had a tree fall on one of the power lines cutting through the property. You can see the picture below. Luckily the power company came out and cleared it prior to the race, otherwise we likely would have had to route folks around that section. 

			<Gallery images={FINAL} enableImageSelection={false} />
		</Typography>
	</Grid>
	<br /><br /><br />
	</Grid>
  );
}