import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

// Import react grid gallery
import Gallery from "react-grid-gallery";

// Import all photos for the gallery
import { IMAGES } from './PhotoGalleryDefinition';


const useStyles = makeStyles((theme) => ({
  pageTitle: {
	  borderBottom: `1px solid ${theme.palette.background.default}`,
  },
}));


export default function Photos() {
	const classes = useStyles();

  return (
	<Grid container>
		<Grid item xs={12}>
			<Typography component="h2" variant="h4" className={classes.pageTitle} gutterBottom>
				Photos
			</Typography>

			<Typography component="body2" color="primaryText" gutterBottom>
				Click to view more detail on photos from the race, course work, and the property in general. <br />
				Professional photos for the race are taken by  
					<Link 
						href="https://www.instagram.com/cheyenneautumnpfahl/" rel="noopener noreferrer" target="_blank"> <strong>Cheyenne Pfahl</strong></Link><br />
				Custom wooden medals created by <Link href="https://raggedcuts.com/" rel="noopener noreferrer" target="_blank"> <strong>Ragged Cuts Custom Awards</strong></Link>.
			</Typography>

			<Gallery images={IMAGES} enableImageSelection={false}  />

		</Grid>
	</Grid>
  );
}
